.callback {

	&_error {
		color: #EB5757;
	}
	form {
		margin: 4rem auto 0;
		text-align: center;

		label {
			@extend .transition;
			display: block;
			cursor: pointer;
			margin-top: 3rem;

			&:hover {
				input, textarea {
					border-bottom: .1rem solid $c-DeepBlue;
				}
				textarea {
					border: .1rem solid $c-DeepBlue;
				}
				& > * {
					color: $c-DeepBlue;
				}
			}

			& > * {
				@include text($f-Text, $middle, 1.4rem, 1);
				@extend .transition;
			}

			span {
				display: inline-block;
				width: 100%;
				text-align: left;
			}
		}

		input, textarea {
			margin-top: .5rem;
			padding: 8px 12px;
			border: none;
			border-bottom: 1px solid $c-Black;
			transition: .3s ease-in;
			width: 100%;
			@extend .transition;
		}

		textarea {
			border: 1px solid $c-Black;
			border-radius: 6px;
			line-height: 1.25em;
			height: 12rem;
		}

		button {
			margin: 3rem auto 0;
		}
	}
}

.modal {
	@include position();
	@include size();
	z-index: 20;
	position: fixed;
	background-color: rgba($c-White, .75);
	display: none;
	#closeModal, .close-modal {
		@include position(0, calc(100% + 1.5rem));
		@include size(4.4rem, 4.4rem);

		img {
			@include transition();

			&:hover {
				filter: drop-shadow(0 0 5px rgba($c-Black, 0.2));
			}
		}
	}

	&_header {
		@extend .flex;
		width: 100%;
		background-color: $c-DeepBlue;
		padding: 3rem;
	}

	.callback_wrapper {
		background: $c-Gray;
		box-shadow: 0 4px 94px rgba(6, 0, 43, 0.06);
		width: 50rem;
		max-width: 100%;
		position: relative;

		#formModal {
			padding: 3rem;
			margin-top: 0;
		}

		label {
			width: 100%;
			display: block;
			max-width: 100%;
			&:first-child {
				margin-top: 0 !important;
			}
			img {
				margin-right: 1.5rem;
			}
		}

		input, textarea {
			background: $c-Gray;
		}
		#file {
			display: none;
		}
	}

	&.open {
		@include flex();
		animation: modalShow 0.3s linear forwards;
	}

	&.hide {
		animation: modalHide 0.3s linear forwards;
	}

	@keyframes modalShow {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	@keyframes modalHide {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
}


@media screen and (max-width: 767px) {
	#closeModal {
		top: 0 !important;
		left: 100% !important;
		transform: translate(-100%, -100%);
	}
	.modal {
		&_header {
			padding: 2rem;
		}
	}
	.callback_wrapper {
		max-height: 90vh;
		max-width: 90vw !important;
		#formModal {
			padding: 2rem !important;
			button {
				margin-top: 1.5rem;
			}
		}
		label {
			margin-top: 1.5rem !important;
			input, textarea {
				padding: .5rem 1rem;
			}
			textarea {
				height: 8rem !important;
			}
		}
	}
}