.title {
	@include flex(flex-start);
	img {
		margin-right: 3rem;
	}
	h2 {
		white-space: nowrap;
	}
	h4 {
		margin-top: .8rem;
	}
}
@media screen and (max-width: 767px) {
	.title {
		* {
			white-space: normal !important;
		}
		img {
			display: none;
		}
	}
}