.hero {
	overflow: hidden;
	position: relative;
	@extend .bg;

	&, .row {
		height: calc(100vh);
	}

	&_title {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 6rem 0;
		background: linear-gradient(90deg, rgba($c-Support, .8) 20%, rgba($c-DeepBlue, .8) 80%);

		.h1 {
			white-space: nowrap;
		}
	}
}

.info {
	&_tabs {
		@include flex(flex-start, normal);
		margin-top: 4rem;

		&_item {
			@include text($f-Title, $middle, 1.2rem);
			@extend .flex;
			@extend .transition;
			background-color: $c-White;
			width: calc(100% / 6);
			position: relative;
			color: $c-DeepBlue;
			padding: 1.8rem .5rem;
			overflow: hidden;

			&::after {
				content: attr(data-text);
				@include text($f-Title, $middle, 1.2rem);
				@include position();
				@extend .flex;
				@extend .full-size;
				@extend .transition;
				padding: 1.8rem .5rem;
				color: white;
				opacity: 0;
				pointer-events: none;
				background: $g-DeepBlue;
			}

			&.active {
				background-color: $c-Transparent;

				&::after {
					opacity: 1;
				}
			}

			&::before {
				content: '';
				display: block;
				@include size(1px);
				@include position(0, calc(100% - .1rem));
				z-index: 1;
				background-color: $c-DeepBlue;
			}

			&:first-child {
				border-radius: .6rem 0 0 .6rem;
			}

			&:last-child {
				border-radius: 0 .6rem .6rem 0;

				&::before {
					display: none;
				}
			}
		}
	}

	&_content {
		margin-top: 4rem;
		display: none;
		opacity: 0;
		visibility: hidden;
		@extend .white;
		@extend .transition;

		&.active {
			display: block;
			visibility: visible;
			animation: fadeIn 0.3s ease-in forwards;
		}

		@keyframes fadeIn {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}


		img {
			border-radius: .6rem;
		}

		.h3 {
			margin-bottom: 3rem;
		}

		label {
			display: block;
			margin-top: 2rem;

			span {
				padding-left: 4rem;
				display: inline-block;
			}
		}

		&_text {
			@include flex(space-between, flex-start);
			flex-direction: column;
		}
	}

	.btn_blue {
		margin-top: 2.5rem;
	}
}

.stages {
	&_card {
		margin-top: 4rem;
		border-radius: .6rem;
		box-shadow: 0 10px 20px rgba($c-Black, 0.1);
		padding: 2.4rem 1.2rem 1.5rem;
		position: relative;
		height: 22rem;
		color: $c-DeepBlue;
		@extend .transition;

		&:hover {
			box-shadow: 0 10px 20px rgba($c-Black, 0.2);
		}

		.text {
			margin-top: 1.5rem;
			line-height: 1.5em;
		}

		img {
			height: 5.8rem;
		}

		&_arrow {
			height: auto !important;
			right: 1.2rem;
			top: 4rem;
			position: absolute;
		}
	}
}

.articles {
	.row {
		flex-wrap: nowrap;
	}

	.h2 {
		margin-top: 1rem;
	}

	.text-small {
		margin-top: .5rem;
	}

	.btn_blue {
		margin-top: 4rem;
	}

	#articles {
		width: calc(100% + ((100vw - 114rem) / 2) + 3rem);
		padding-bottom: 3rem;
	}

	&_cards {
		display: flex !important;
		padding-top: 1rem;

		&_item {
			min-width: 35rem;
			height: 30rem;
			background-color: $c-Support;
			border-radius: .6rem;
			overflow: hidden;
			box-shadow: -.1em .5rem 1rem rgba($c-DeepBlue, 0.6);
			margin-right: -5rem;
			@include transition(.3s);
			z-index: 1;

			&:hover {
				margin-right: 0;
				transform: translateY(-1rem)
			}

			img {
				@include size(100%, 17rem);
				object-fit: cover;
			}

			&_content {
				@include flex(space-between, flex-start);
				flex-direction: column;
				height: calc(100% - 17rem);
				padding: 1.5rem;

				.more {
					@include text($f-Text, $middle, 1.6rem, 1);
					color: $c-White;
					text-transform: uppercase;
					position: relative;

					&::before {
						content: '';
						transform: translateY(-50%);
						@include position(50%, calc(100% + 1rem));
						@include size(1.3rem, 100%);
						@include bg(url('../img/arrow-article.png'), cover);
					}
				}
			}
		}
	}
}

.pluses {
	@extend .bg;

	.title {
		margin-bottom: 4rem;
	}

	.col-lg-6 {
		position: relative;
		padding: 0;
		height: 110px;
		&:nth-child(1) {
			.pluses_card:hover {
				height: 202px;
			}
		}
		&:nth-child(2) {
			.pluses_card:hover {
				height: 202px;
			}
		}
		&:nth-child(3) {
			.pluses_card:hover {
				height: 229px;
			}
		}
		&:nth-child(4) {
			.pluses_card:hover {
				height: 310px;
			}
		}
		&:nth-child(5) {
			.pluses_card:hover {
				height: 283px;
			}
		}
		&:nth-child(6) {
			.pluses_card:hover {
				height: 175px;
			}
		}
		&:nth-child(7) {
			.pluses_card:hover {
				height: 283px;
			}
		}
		&:nth-child(8) {
			.pluses_card:hover {
				height: 337px;
			}
		}
	}

	&_card {
		margin-top: 1.5rem;
		padding: 1.2rem 1.5rem;
		border-radius: .6rem;
		height: 100%;
		width: 100%;
		overflow: hidden;
		@include transition(.3s);
		position: absolute;
		top: 0;
		left: 0;

		&:hover {
			box-shadow: 0 .4rem 9rem rgba($c-DeepBlue, .7);
			background: $g-DeepBlue;
			z-index: 5;
			.text {
				white-space: normal;
			}
		}

		&_title {
			@include flex(flex-start, flex-end);
			width: 100%;
			.h3 {
				width: 100%;
			}

			img {
				margin-right: 1.5rem;
			}
		}

		.text {
			margin-top: 1rem;
			text-overflow: ellipsis;
			overflow: hidden;
			transition-delay: .5s;
		}
	}
}

@media screen and (max-width: 1199px) {
	.stages {
		&_card {
			height: 20rem;
		}
	}
	.pluses {
		height: auto;
		.col-lg-6 {
			padding: 0;
			&:nth-child(1) {
				.pluses_card:hover {
					height: 229px;
				}
			}
			&:nth-child(4) {
				.pluses_card:hover {
					height: 385px;
				}
			}
			&:nth-child(5) {
				.pluses_card:hover {
					height: 302px;
				}
			}
			&:nth-child(6) {
				.pluses_card:hover {
					height: 202px;
				}
			}
			&:nth-child(7) {
				.pluses_card:hover {
					height: 302px;
				}
			}
			&:nth-child(8) {
				.pluses_card:hover {
					height: 410px;
				}
			}
		}
	}
	.info {
		&_tabs {
			&_item {
				font-size: 1rem;
				&::after {
					font-size: 1rem;
				}
			}
		}
	}
}

@media screen and (max-width: 991px) {
	.info {
		&_tabs {
			flex-wrap: wrap;
			justify-content: center;

			&_item {
				width: 33.33%;

				&:first-child {
					border-radius: .6rem 0 0 0;
				}

				&:nth-child(3) {
					border-radius: 0 .6rem 0 0;
				}

				&:nth-child(4) {
					border-radius: 0 0 0 .6rem;
				}

				&:nth-child(6) {
					border-radius: 0 0 .6rem 0;
				}

				&:last-child {
					border-radius: 0 0 .6rem .6rem;
				}
			}
		}
	}
	.articles {
		#articles {
			width: 100%;
		}
		&_logo {
			display: none;
		}
		.row {
			flex-wrap: wrap;
		}
		&_cards {
			&_item {
				min-width: 21rem;
				height: 21rem;
				margin-right: 1.5rem;
				img {
					height: 50%;
				}
				&_content {
					height: 50%;
					padding: 1rem .5rem;
				}
			}
		}
	}
	.pluses {
		&_card {
			height: 100%;
			&:hover {
				height: 100% !important;
			}
			.text {
				white-space: normal;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.hero_title .h1 {
		white-space: normal;
	}
	.info {
		&_tabs {
			&_item, &_item::after {
				padding: .7rem 1rem;
				font-size: 1.2rem;
			}
		}
	}
}

@media screen and (max-width: 575px) {
	.info {
		&_tabs {
			&_item, &_item::after {
				font-size: .8rem;
			}
		}
	}
	.stages {
		&_card {
			@include flex(flex-start);
			height: 8rem;
			padding: 1.5rem;
			padding-right: 4.5rem;

			.text {
				margin-top: 0;
			}

			img {
				@include size(4.8rem, 4.8rem);
				margin-right: 1.5rem;
			}

			img + &_arrow {
				transform: rotate(90deg) translateY(-50%);
				height: 1.6rem;
				width: 1.4rem;
				top: 50%
			}
		}
	}
}
