.full-set {
	.h3 {
		font-size: 2rem;
	}

	&_item {
		@extend .transition;
		margin-top: 4rem;
		padding-top: 3rem;
		background-color: $c-Gray;

		&:hover {
			background-color: rgba($c-Blue, .4);

			& div {
				border-color: $c-White;
			}
		}

		.text.big, .endPrice {
			@extend .transition;
		}

		&_tab {
			&_title {
				@include flex(space-between);
				@extend .transition;
				flex-wrap: wrap;
				padding: 1rem 2rem;
				border-top: 1px solid $c-Black;
				cursor: pointer;

				& > * {
					pointer-events: none;
				}

				&:first-child {
					margin-top: 1.5rem;
					border-top: none;
				}

				span {
					font-weight: bold;
					font-size: 2.4rem;
				}

				&.active .full-set_item_tab_content {
					max-height: 100rem;
					visibility: visible;
					opacity: 1;
				}
			}

			&_content {
				@extend .transition;
				max-height: 0;
				visibility: hidden;
				opacity: 0;
			}
		}

		&_start-price {
			margin-top: 1rem;
		}

		&_subtitle {
			@include text($f-Title, $bold, 1.8rem);
			margin-top: 1.5rem;
			padding: 0 2rem;
		}

		&_end-price {
			@extend .transition;
			border-top: 1px solid $c-Black;
			margin-top: 1.5rem;
			padding: 1.5rem 1.5rem 3rem;

			button {
				margin: 1rem auto 0;
			}
		}

		label {
			@include flex(flex-start);
			line-height: 1;
			cursor: pointer;
			margin-top: 2rem;
			padding-left: 2.5rem;
			padding-right: 2rem;
		}
	}
}

.next-service {
	&_item {
		@extend .bg;
		position: relative;
		border-radius: .6rem;
		overflow: hidden;
		height: 40rem;
		@extend .transition;

		&:hover {
			box-shadow: 0 10px 20px rgba($c-DeepBlue, 0.15)
		}

		.text {
			margin-top: 1rem;
		}

		&_content {
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 1rem 2rem;
			background: linear-gradient(90deg, rgba($c-Support, .85) 20%, rgba($c-DeepBlue, .85) 80%);
		}
	}

	.next, .prev {
		line-height: 1;
		margin-top: 2.5rem;
	}

	.next {
		@include bg(url('../img/arrow-next.png'), 1.5rem);
		text-align: right;
		padding-right: 2rem;
		background-position-x: 100%;
	}

	.prev {
		@include bg(url('../img/arrow-prev.png'), 1.5rem);
		padding-left: 2rem;
		background-position-x: 0;
	}
}

@media screen and (max-width: 767px) {
	.next-service {
		padding: 0 !important;
		&_item {
			height: 12rem;

			&_content {
				.h3 {
					font-size: 1.2rem;
				}
				p {
					display: none;
				}
			}
		}
	}
}