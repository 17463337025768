.footer {
	padding: 6rem 0;
	background: $g-DeepBlue;
	color: $c-White;
	&_main {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	&_logo {
		height: 5.8rem;
	}
	.link {
		@include transition(0.2s);
		&:hover {
			opacity: 0.5;
		}
	}
	&_info {
		margin-top: 2.5rem;
		&_value {
			@extend .text;
			color: $c-White;
		}
		&_name {
			@include text($f-Title, $middle, 1.4rem, 1);
			color: $c-White;
		}
	}
	&_phone-wrapper {
		margin-top: 2.5rem;
		@include flex(flex-start, flex-start);
	}
	&_phone {
		@extend .text;
		line-height: 3rem;
		color: $c-White;
		&_callback {
			position: relative;
			margin-top: .5rem;
			display: inline-block;
			@extend .text;
			text-transform: uppercase;
			color: $c-White;
			&::before {
				content: "";
				background-color: white;
				@include position(calc(100% + 1px));
				@include size(100%, 1px);
				@include transition(0.2s);
			}
			&:hover {
				&::before {
					opacity: 0;
				}
			}
		}
	}
	&_menu-wrapper {
		@include flex(flex-end, stretch);
		flex-direction: column;
	}
	&_menu {
		&_mobile{
			display: none;
		}
		&_item {
			margin-top: 3rem;
			a {
				@extend .text;
				text-transform: uppercase;
				color: $c-White;
				position: relative;
				display: inline-block;
				overflow: hidden;
				line-height: 1;
				&::before {
					content: '';
					background-color: $c-White;
					@include transition();
					@include position(calc(100% - 1px), -100%);
					@include size(100%, 1px);
				}
				&:hover {
					&::before {
						left: 0;
					}
				}
			}
			&:first-child {
				margin-top: 0;
			}
		}
	}
	&_social {
		margin-top: 2.5rem;
		@include flex(space-between);
		&_item {
			@include size(4rem, 4rem);
			@include flex();
			@include transition();
			&:hover {
				transform: scale(1.1);
			}
		}
	}
	#map {
		height: 100%;
	}
}


@media screen and (max-width: 991px) {
	.footer {
		&_map {
			display: none;
		}
		.row {
			justify-content: center;
		}
	}
}
@media screen and (max-width: 767px) {
	.footer{
		padding: 35px 0;
		&_menu{
			display: none;
			&_item{
				margin-top: 15px;
				text-align: center;
				a{
					font-size: 1.8rem;
				}
			}
			&_mobile{
				display: block;
				margin-top: 15px;
				margin-bottom: 30px;
			}
		}
		&_main{
			@include flex(center, center);
			flex-direction: column;
		}
		&_info{
			text-align: center;
			&_value{
				font-size: 1.25rem;
				font-weight: $middle;
			}
		}
		&_phone-wrapper{
			text-align: center;
			justify-content: center;
			align-items: center;
			font-weight: $middle;
			flex-direction: column;
			img{
				margin-bottom: 5px;
			}
		}
		&_social{
			justify-content: center;
			margin-top: 20px;
			&_item{
				margin: 0 7px;
			}
		}
	}
}
