.btn {
	@include text($f-Title, $middle, 1.6rem, 1);
	@include border($c-White);
	@extend .white;
	@extend .shadow;
	@extend .transition;
	@extend .flex;
	text-transform: uppercase;
	padding: 1.5rem 3rem;
	border-radius: .6rem;


	&_blue {
		@extend .btn;
		background: $g-DeepBlue;
		&:hover {
			background: $g-DeepBlueHover;
		}
	}

	&_white {
		@extend .btn;
		padding: 0;
		@include size(5rem, 5rem);
	}
	&_phone {
		@extend .btn_blue;
		padding: 0;
		width: 5rem;
		height: 5rem;
	}
}

@media screen and (max-width: 991px) {
	.btn {
		&_phone {
			width: 4rem;
			height: 4rem;
		}
	}
}

@media screen and (max-width: 767px) {
	.btn {
		font-size: 1.4rem;
		&_blue {
			padding: 1.2rem 3rem;
		}
		&_phone {
			padding: 0;
		}
	}
}

