*,
*::before,
*::after {
	box-sizing: border-box;
	outline: none;
	margin: 0;
	padding: 0;
	cursor: none !important;
}

*:focus,
*:active {
	outline: none;
}
:root {
	-webkit-font-smoothing: antialiased;
}

::selection {
	color: $c-Blue;
	background-color: transparent;
	text-shadow: none;
}

html {
	font-size: 62.5%;
	overflow-x: hidden;
}

body {
	font-size: 1.6rem;
}

#bar {
	max-height: 100vh;
	min-height: 100vh;
}

a {
	text-decoration: none;
}

textarea {
	resize: none;
}

menu,
ul,
ol {
	list-style-type: none;
}

img {
	vertical-align: middle;
	max-width: 100%;
}

button {
	cursor: pointer;
	border: none;
}

section {
	padding: 10rem 0;
	position: relative;
	&.small {
		padding: 7rem 0;
	}
}

canvas {
	display: block;
	vertical-align: bottom;
}

#particles-js {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $g-DeepBlue;
	z-index: -1;
}

.count-particles {
	background: $c-Black;
	position: absolute;
	top: 48px;
	left: 0;
	width: 80px;
	color: $c-Blue;
	font-size: .8em;
	text-align: left;
	text-indent: 4px;
	line-height: 14px;
	padding-bottom: 2px;
	font-family: Helvetica, Arial, sans-serif;
	font-weight: bold;
}

@media screen and (max-width: 767px) {
	section, footer {
		padding: 7rem 0;
	}
}


@media screen and (max-width: 575px) {
	.row {
		margin-left: 0;
		margin-right: 0;
	}
	.col,
	.col-1,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-10,
	.col-11,
	.col-12,
	.col-auto,
	.col-lg,
	.col-lg-1,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg-auto,
	.col-md,
	.col-md-1,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md-auto,
	.col-sm,
	.col-sm-1,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-auto,
	.col-xl,
	.col-xl-1,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xl-10,
	.col-xl-11,
	.col-xl-12,
	.col-xl-auto {
		padding: 0 5px;
	}
}
