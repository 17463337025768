#cursor, #aura {
	position: fixed;
	top: 0;
	left: 0;
	border-radius: 100%;
	transition: .5s cubic-bezier(.75, -1.25, .3, 2.3) transform,
	.2s cubic-bezier(.75, -1.25, .3, 2.3) opacity,
	150ms ease-in background-color,
	.2s ease-in-out box-shadow;
	user-select: none;
	pointer-events: none;
	z-index: 1000000001;

	&.hidden {
		transform: scale(.01);
	}
}

#cursor {
	@include size(1rem, 1rem);
	background-color: $c-Blue;
	transform: scale(1);

	&.active {
		opacity: 0;
		transform: scale(0);
	}
}

#aura {
	@include size(4.6rem, 4.6rem);
	@include border(rgba($c-Blue, .5));
	background-color: rgba($c-Blue, .25);
	transform: translate(.6rem, .6rem);

	&.active {
		@include border(rgba($c-Blue, .8), .12rem);
		transform: translate(.5rem, .5rem) scale(1.33);
		box-shadow: 0 0 1.5rem rgba($c-Blue, .3);
	}
}


@media screen and (max-width: 767px) {
	#cursor, #aura {
		display: none;
	}
}