// Colors
$c-White: #FFFFFF;
$c-Blue: #6DB4FE;
$c-Support: #02477E;
$c-DeepBlue: #002A57;
$c-Black: #4F4F4E;
$c-Transparent: rgba(0,0,0,0);
$c-Gray: #F0F0F0;

// Gradient
$g-DeepBlue: linear-gradient(90deg, $c-Transparent 20%, $c-DeepBlue 80%), $c-Support;
$g-DeepBlueHover: linear-gradient(90deg, $c-Transparent 20%, $c-DeepBlue 80%), $c-DeepBlue;

// Fonts
$f-Title: "Montserrat", Arial, sans-serif;
$f-Text: "Raleway", Arial, sans-serif;

$normal: 400;
$middle: 500;
$bold: 700;