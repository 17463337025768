.simplebar-track {


	&.simplebar-horizontal {

		height: 24px;
		overflow: visible;

		&::before {
			content: '';
			@include size(100%, 2px);
			@include position(10px, 2px);
			background-color: $c-White;
		}

		.simplebar-scrollbar {
			z-index: 5;
			height: 100%;

			&::before {
				background: $g-DeepBlue;
				box-shadow: 0 0 4px $c-Blue;
				border-radius: 0;
				height: 8px;
				top: 5px;
				opacity: 1;
			}
		}
	}
}