.header {
	background: $g-DeepBlue;
	@extend .absolute;
	@extend .transition;
	@include size(100%, 10rem);
	opacity: .8;
	z-index: 7;

	&.fixed {
		animation: headerFix 0.3s linear 0.2s forwards;
		box-shadow: 0 0 1rem rgba($c-DeepBlue, .3);
		opacity: 1;
	}

	.row {
		height: 10rem;
	}

	&_logo {
		@include flex(flex-start);

		img {
			object-fit: cover;
		}
	}

	&_link {
		@extend .text-small;
		color: $c-White;
		white-space: nowrap;
	}

	&_nav {
		@include flex(space-between);
	}

	&_menu {
		height: 100px;
		flex-grow: 1;
		@include flex(flex-start);
		padding-left: 3rem;

		&_item {
			@extend .flex;
			margin-right: 6.5rem;
			position: relative;

			&:last-child {
				margin-right: 0;
			}

			&::before {
				content: "";
				background-color: $c-White;
				opacity: 0;
				@include position(calc(100% + .5em), 0);
				@include size(100%, .1rem);
				@include transition(0.15s);
			}

			&:hover::before {
				opacity: 1;
				@include position(100%, 0);
			}

			&.active::before {
				opacity: 1;
				@include position(calc(100% - .6rem), 0);
			}
		}
	}

	#cross {
		display: none;
	}

	&_burger {
		@extend .flex;
		display: none;
	}
}
@keyframes headerFix {
	from {
		position: fixed;
		top: -10rem;
	}
	to {
		position: fixed;
		top: 0;
	}
}

@media screen and (max-width: 1199px) {
	.header {
		&_menu {
			&_item {
				margin-right: 5rem;
			}
		}
	}
}

@media screen and (max-width: 991px) {
	.header {
		&_menu {
			padding-left: 0;
			&_item {
				margin-right: 3rem;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.header {
		opacity: 1;
		&_logo {
			justify-content: center;
		}

		&_menu {
			display: none;
			padding-left: 0;
		}

		&_burger {
			display: flex;
		}

		&_nav {
			justify-content: flex-end;
		}

		&_menu {
			@include position(0,0,fixed);
			@include size(100vw, 100vh);
			@include bg(url('../img/hero-bg-mobile.jpg'));
			flex-direction: column;
			z-index: 5;
			padding-top: 20vh;

			&_item {
				margin: 2rem 0 0;
				height: auto;
				display: block;
				& > * {
					font-size: 1.6rem !important;
				}

				&:first-child {
					margin-top: 0;
				}

				&.active::before {
					@include position(100%, 0);
				}
			}

			&.open {
				@include flex(flex-start);
				animation: headerMobileShow 0.3s linear forwards;
			}

			&.hide {
				animation: headerMobileHide 0.3s linear forwards;
			}

			@include transition();
		}

		#cross {
			display: block;
			@include position(15px, 100%);
			@include size(2.6rem, 2.6rem);
			transform: translateX(calc(-100% - 15px));
		}

		@keyframes headerMobileShow {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
		@keyframes headerMobileHide {
			from {
				opacity: 1;
			}
			to {
				opacity: 0;
			}
		}
	}
}