.checkbox {
	position: absolute;
	z-index: -1;
	opacity: 0;

	& + span {
		position: relative;

		&::before {
			content: "";
			@include transition(150ms);
			position: absolute;
			display: block;
			border-radius: 6px;
			top: 1.55rem;
			left: 0;
			transform: translateY(-50%);
			@include size(24px, 24px);
			@include border($c-White, 2px);
			background-color: $c-Transparent;
		}
	}
	&.revers + span {
		&::before {
			@include border($c-DeepBlue, 2px);
		}
	}

	&:checked + span::before {
		background-color: $c-White;
		@include bg(url('../img/ok.png'), 1.5rem);
	}
	&.revers:checked + span::before {
		background-color: $c-DeepBlue;
		@include bg(url('../img/ok-reverse.png'), 1.5rem);
	}

}