.partners {
	margin-top: 4rem;

	&_item {
		@include border($c-Gray);
		@extend .flex;
		@extend .transition;
		height: 20rem;
		padding: 4rem 0;

		&:hover {
			box-shadow: 0 4px 26px rgba($c-Black, 0.12);
			border-radius: .6rem;
		}
	}

	&_wrapper {
		background-color: $c-Gray;
		position: relative;
		padding: 0;

		.modal_header {
			padding: 1.5rem 3rem;
			justify-content: flex-start;
		}
		.modal_content {
			padding: 3rem;
			margin-top: 0;
			@extend .deep-blue;

			.title-small {
				span:last-child {
					display: none;
				}
			}
		}
		.btn_blue {
			margin-top: 2.5rem;
		}
	}
}

@media screen and (max-width: 991px) {
	.partners {
		div {
			height: 15rem;
		}
	}
}

@media screen and (max-width: 767px) {
	.partners {
		div {
			height: 10rem;
		}
	}
}

@media screen and (max-width: 575px) {
	.partners {
		div {
			height: 7rem;
		}
	}
}