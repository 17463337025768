.reviews {
	overflow: hidden;

	#reviewsSlider {
		overflow: visible;
		margin-top: 5rem;
	}

	#reviewsSliderPrev,
	#reviewsSliderNext {
		@include bg(url('../img/slider-arrow.png'), contain);
		@include size(28px, 34px);
		@extend .transition;
		cursor: pointer;
		z-index: 2;
		position: absolute;
		top: calc(50% - 10px);

		&:hover {
			opacity: .8;
		}
	}

	#reviewsSliderPrev {
		left: 20%
	}

	#reviewsSliderNext {
		right: 20%;
		transform: rotate(180deg);

		&:hover {
			opacity: .8;
		}
	}

	#reviewsSliderNext &_arrow {
		transform: rotate(180deg);
	}

	.text, .h3 {
		margin-top: 1rem;
	}

	&_card {
		box-shadow: 0 10px 20px rgba(137, 144, 160, 0.15);
		background: $c-White;
		text-align: center;
		border-radius: 6px;
		padding: 1.5rem;

		img {
			@extend .transition;
			@include size(100%, auto);
			max-width: 220px;
			max-height: 220px;
			border-radius: 6px;
			object-fit: cover;
		}
	}

	.swiper-slide {
		visibility: hidden;
		transform: scale(0.7);
		opacity: .5;

		img {
			transition: none;
		}
	}

	.swiper-slide-next,
	.swiper-slide-prev,
	.swiper-slide-active {
		visibility: visible;
		@include transition();

		.text.normal {
			margin-top: 25px;
			font-size: 1.75rem;
		}
	}

	.swiper-slide-active {
		transform: scale(1);
		opacity: 1;

		.text.normal {
			font-size: 1.5rem;
			margin-top: 10px;
		}
	}

	&_item {
		padding: 2.5rem;
		box-shadow: 0 10px 20px rgba($c-Black, 0.15);
		border-radius: .6rem;
		margin-top: 2.5rem;
		@include flex(flex-start, flex-start);

		&:first-child {
			margin-top: 0;
		}

		img {
			@include size(11rem, 11rem);
			margin-right: 2.5rem;
		}

		.h3 {
			margin-top: 0;
		}
	}

	.callback {
		padding: 0 1.5rem;
	}

	.pagination {
		@extend .flex;
		margin-top: 2.5rem;

		span {
			@include text($f-Title, $bold, 1.6rem, 1);
			padding: .6rem 1.3rem;
			color: $c-DeepBlue;
			border-radius: .6rem;

			&.active {
				color: $c-White;
				background: $g-DeepBlue;
			}
		}

		a {
			padding: 0 1.3rem;
			line-height: 1;
			display: inline;

			img {
				height: 1.2rem;
				margin-top: -.2rem;
			}
		}
	}

}

@media screen and (max-width: 1199px) {
	.swiper-slide-active .reviews_card img {
		width: 180px;
		height: 180px;
	}
}

@media screen and (max-width: 991px) {
	.reviews {
		.swiper-slide {
			&-active {
				transform: scale(1);
			}

			img {
				width: 116px !important;
				height: 116px !important;
			}
		}

		#reviewsSlider {
			margin-top: 0;
		}
	}
}

@media screen and (max-width: 767px) {
	.reviews {

		#reviewsSliderNext {
			right: 5%;
		}

		#reviewsSliderPrev {
			left: 5%;
		}

		#reviewsSlider {
			padding: 3rem 0 0 !important;
		}
		&_item {
			flex-direction: column;
			align-items: center;
			img {
				margin-right: 0;
			}
			&_content {
				margin-top: 1.5rem;
				text-align: center;
			}
		}
		.callback {
			margin-top: 3rem;
		}
	}
}