h1, .h1 {
	@include text($f-Title, $bold, 4.8rem);
}

h2, .h2 {
	@include text($f-Title, $bold, 3.4rem);
	text-transform: uppercase;
}

h3, .h3 {
	@include text($f-Title, $bold, 2.2rem);
}

.title-small {
	@include text($f-Title, $bold, 1.2rem);
	span {
		padding: 0 1rem;
	}
}

p, .text {
	@include text($f-Text, $normal, 1.8rem, 1.5em);
	&-small {
		@include text($f-Text, $normal, 1.6rem, 1.5em);
	}
}
.deep {
	background: $g-DeepBlue;
}
.bold {
	font-weight: bold !important;
}

.white {
	color: $c-White;
}
.gray {
	color: $c-Gray;
}
.deep-blue {
	color: $c-DeepBlue;
}
.blue {
	color: $c-Blue;
}
.black {
	color: $c-Black;
}

.mt {
	margin-top: 1.5em !important;
}

.center {
	text-align: center;
}

@media screen and (max-width: 991px) {
	h1, .h1 {
		font-size: 4rem;
	}
	h2, .h2 {
		font-size: 3rem;
	}
	h3, .h3 {
		font-size: 1.6rem;
	}
	p, .text {
		@include text($f-Text, $normal, 1.6rem, 1.5em);
		&-small {
			@include text($f-Text, $normal, 1.4rem, 1.5em);
		}
	}
}

@media screen and (max-width: 767px) {
	h1, .h1 {
		font-size: 3.2rem;
	}
	h2, .h2 {
		font-size: 2.4rem;
	}
	p, .text {
		@include text($f-Text, $normal, 1.4rem, 1.5em);
		&-small {
			@include text($f-Text, $normal, 1.2rem, 1.5em);
		}
	}
}

@media screen and (max-width: 575px) {
	h1, .h1 {
		font-size: 2.6rem;
	}
	h2, .h2 {
		font-size: 2rem;
	}
}