.radio {
	position: relative;
	margin-right: 1rem;

	&::after,
	&::before {
		content: "";
		@include transition();
		position: absolute;
		display: block;
		border-radius: 50%;
	}

	&::before {
		top: -4px;
		left: -4px;
		@include size(1.8rem, 1.8rem);
		@include border($c-DeepBlue, 2px);
		background-color: $c-White;
	}

	&::after {
		top: 1px;
		left: 1px;
		@include size(8px, 8px);
		border-radius: 50%;
		background: white;
	}

	&:hover::before,
	&:checked::before {
		border-color: $c-DeepBlue;
	}

	&:checked::after {
	}

	&:checked::after {
		background: $c-DeepBlue;
	}

	&:disabled {
		&::before {
			border-color: $c-White;
		}
	}
}