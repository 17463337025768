@mixin fontFace($font-family, $file-path, $weight: normal, $style: normal) {
	@font-face {
		font-family: $font-family;
		font-style: $style;
		font-weight: $weight;
		font-display: swap;
		src: url("#{$file-path}.eot");
		src: local($font-family), url("#{$file-path}.eot?#iefix") format("embedded-opentype"),
		url("#{$file-path}.woff2") format("woff2"),
		url("#{$file-path}.woff") format("woff");
		unicode-range: U+0030-0039,U+0400-045F,U+0041-0058,U+0061-007A, U+0490-0491,U+04B0-04B1,U+2116;
	}
}

@mixin text($family, $weight, $size, $line-height: 1.25em) {
	font: {
		family: $family;
		weight: $weight;
		size: $size;
	}
	line-height: $line-height;
}

@mixin transition($duration: .4s) {
	transition: ease-in-out $duration;
}

@mixin bg($image, $size: cover) {
	background-image: $image;
	background-size: $size;
	background-repeat: no-repeat;
	background-position: center;
}

@mixin flex($justifyContent: center, $alignItems: center) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
}

@mixin position($top: 0, $left: 0, $position: absolute) {
	position: $position;
	top: $top;
	left: $left;
}

@mixin size($width: 100%, $height: 100%) {
	width: $width;
	height: $height;
}

@mixin border($color, $size: 1px) {
	border: $size solid $color;
}


.flex {
	@include flex();
}

.absolute {
	@include position();
}

.full-size {
	@include size();
}

.transition {
	@include transition();
}

.bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.shadow {
	box-shadow: 0 5px 10px rgba($c-Black, 0.15);
}