.directions {
	padding-bottom: 0;

	.more {
		margin: 0 auto;
	}

	&_item {
		overflow: hidden;

		&.active {
			.directions_item {
				&_head {
					.btn_white {
						img {
							transform: rotate(180deg);
						}
					}
				}

				&_body {
					max-height: 100rem;
					visibility: visible;
					opacity: 1;
				}
			}
		}

		&_title {
			h3, .btn_white {
				pointer-events: none;
			}
		}

		&_head {
			height: 40rem;
			border-radius: .6rem;
			position: relative;

			.btn_white {
				img {
					@extend .transition;
				}
			}
		}

		&_title {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 2.5rem 3rem;
			background: linear-gradient(90deg, rgba($c-Support, .8) 20%, rgba($c-DeepBlue, .8) 80%);
			@include flex(space-between);

			.btn_white {
				img {
					pointer-events: none;
				}
			}
		}

		&_body {
			padding: 3rem;
			max-height: 0;
			visibility: hidden;
			opacity: 0;
			@extend .transition;

			p.text-small {
				margin-top: 1.5rem;

				&:first-child {
					margin-top: 0;
				}
			}

			label {
				display: block;
				margin-top: 2rem;

				&:first-child {
					margin-top: 3rem;
				}

				span {
					padding-left: 4rem;
					display: inline-block;
				}
			}
		}

		&_btn {
			@include flex(flex-start);
			margin-top: 3rem;

			a {
				margin-left: 3rem;
				text-transform: uppercase;
				position: relative;

				&::before {
					content: '';
					@include position(100%);
					background-color: $c-DeepBlue;
					@include size(100%, .1rem);
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.directions {
		&_item {
			&_head {
				height: 15rem;
			}

			&_title {
				padding: 1.5rem .8rem;
				height: 5rem;

				.h3 {
					font-size: 1.4rem;
				}

				.btn_white {
					@include size(2.4rem, 2.4rem);

					img {
						width: 1rem;
					}
				}
			}

			&_body {
				padding: 1.5rem;
			}

			&_btn {
				a {
					margin-left: 1.5rem;
					font-size: 1.2rem;
				}
			}
		}
	}
}
