.gallery {
	&_item {
		max-height: 67rem;
		margin-top: 3rem;
		overflow: hidden;
		img {
			object-fit: cover;
			max-height: 100%;
			@extend .full-size;
		}
		&-1 {
			a {
				height: 100%;
			}
		}
		&-2 {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			a:nth-child(1), a:nth-child(2) {
				height: 25.5rem;
				width: calc(50% - 1.5rem);
			}
			a:last-child {
				margin-top: 3rem;
				height: 38.5rem;
				width: 100%;
			}
		}
		&-3 {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			a:nth-child(3), a:nth-child(2) {
				height: 25.5rem;
				width: calc(50% - 1.5rem);
				margin-top: 3rem;
			}
			a:first-child {
				height: 38.5rem;
				width: 100%;
			}
		}
		&-4 {
			a {
				height: 32.5rem;
				width: 100%;
				&:last-child {
					margin-top: 3rem;
				}
			}
		}
	}
	.btn_blue {
		margin: 3rem auto 0;
	}
}